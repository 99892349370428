import { graphql, StaticQuery } from "gatsby";
import * as React from "react";
import MarkdownView from "react-showdown";
import { CONSTANTS } from "../components/constants";
import Layout from "../components/layout";
import Seo from "../components/seo";

export const aboutQuery = graphql`
  query {
    about: strapiOkCarbonAbout {
      id
      content
    }
  }
`;

const IndexPage = () => {
  return (
    <Layout altBg={true}>
      <Seo
        title="About"
        // description= TODO: Shop SEO description!
        meta={[
          {
            property: `og:url`,
            content: `${CONSTANTS.URL}/about/`,
          },
        ]}
      />
      <div className="section">
        <div className="row gtr-uniform aln-center">
          <div className="col-7 col-12-small">
            <StaticQuery
              query={aboutQuery}
              render={(data) => {
                return (
                  <MarkdownView
                    markdown={`${data.about.content}`}
                    className="about"
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
